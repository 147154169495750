<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="vendors"
                  item-text="supplier_name"
                  item-value="id"
                  return-object
                  v-model="form.vendorId"
                  disabled
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceNumber"
                  :rules="[v => !!v || 'Invoice Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuInvoiceDate = false"
                    @change="onInputInvoiceDuration"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Received Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceReceivedDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceReceivedDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceReceivedDate"
                    @input="menuInvoiceReceivedDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="9" lg="9">
            <v-row>
              <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                <v-subheader>DO Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="deliveryOrders"
                  item-text="name"
                  return-object
                  v-model="deliveryOrder"
                  :rules="[v => !!v || 'DO is required']"
                  :filter="filterObjectDo"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.do_num"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="2" lg="2" class="py-0">
                <v-btn color="success" @click="addDeliveryOrder" class="mr-2">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn color="primary" @click="viewTableDo">
                  <v-icon>
                    mdi-file-table-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Term <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.invoiceDuration"
                  suffix="days"
                  @input="onInputInvoiceDuration"
                  :rules="[v => v >= 0 || 'Term must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Due Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDueDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDueDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDueDate"
                    @input="menuInvoiceDueDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Percent Invoice <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  v-if="form.type === 'PO'"
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentageInvoice"
                  suffix="%"
                  @change="onInputInvoice"
                  :rules="percentageInvoiceRules"
                ></v-text-field>
                <v-text-field
                  v-if="form.type === 'DO'"
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentageInvoice"
                  suffix="%"
                  @change="onInputInvoice"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Percent PPN<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentagePpn"
                  suffix="%"
                  :rules="[v => v >= 0 || 'PPN must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.percentagePpn > 0">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxInvoiceSerialNumber"
                  :rules="[v => !!v || 'Tax Serial Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Already DP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ totalPercentage }}%</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Round Type</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  background-color="white"
                  v-model="form.roundType"
                  :items="listRoundType"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Round Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  background-color="white"
                  v-model="form.roundAmount"
                  :disabled="form.roundType === null"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Percent Up To This Invoice</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.percentageUpTo ? form.percentageUpTo.toFixed(2) : 0 }}%</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPH Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="accountName"
                  return-object
                  v-model="pph"
                  :items="accounts"
                  @change="onChangePph"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Discount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{
                  form.poDiscountType === 1
                    ? form.poDiscount
                      ? form.poDiscount.toFixed(2) + "%"
                      : 0
                    : form.poDiscount
                    ? formatPrice(form.poDiscount.toFixed(2))
                    : 0
                }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuTaxDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.taxDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.taxDate"
                    @input="menuTaxDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>DPP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.totalDpp"
                  type="number"
                  persistent-hint
                  :hint="formatPrice(form.totalDpp)"
                  :rules="[v => v >= 0 || 'DPP must more than equal 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickProduct"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <!-- <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Due Date</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.invoiceDueDate }}</span>
                    </v-col> -->
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Currency</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.currency }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Bill To</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.billTo }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>FOB</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.fob }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Address</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.address }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="4" lg="3">
            <v-card outlined>
              <v-card-title class="py-0">Total PO</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.totalPoAfterDiscountAmount"
                  persistent-hint
                  :hint="formatPrice(form.totalPoAfterDiscountAmount)"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="3">
            <v-card outlined>
              <v-card-title class="py-0">Total Inv</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.totalAmount"
                  persistent-hint
                  :hint="formatPrice(form.totalAmount)"
                  @change="onChangeTotalInvoice"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="3">
            <v-card outlined>
              <v-card-title class="py-0">Discount</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.discountAmount"
                  @change="onInputTax"
                  persistent-hint
                  :hint="formatPrice(form.discountAmount)"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="3">
            <v-card outlined>
              <v-card-title class="py-0">Total After Disc</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterDisc) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="3">
            <v-card outlined>
              <v-card-title class="py-0">Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.taxAmount"
                  @change="onInputTax"
                  persistent-hint
                  :hint="formatPrice(form.taxAmount)"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="3">
            <v-card outlined>
              <v-card-title class="py-0">Tax PPH</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.pphAmount"
                  @change="onInputTax"
                  persistent-hint
                  :hint="formatPrice(form.pphAmount)"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="3">
            <v-card outlined>
              <v-card-title class="py-0">Total After Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterTax) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="checkJournal">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-product
      :dialog="dialog"
      :item="item"
      :index="index"
      type="Invoice"
      :accounts="accounts"
      @close="close"
      @save="save"
      @deleteItem="deleteItem"
    ></dialog-product>
    <dialog-view-table
      :dialog="dialogTableDo"
      :deliveryOrders="form.deliveryOrders"
      @close="close"
      @deleteItem="deleteItemTableDo"
      type="DO"
    ></dialog-view-table>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      :currency="form.currency"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogProduct from "@/components/DialogProduct";
import DialogViewTable from "@/components/DialogViewTable";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-purchase-invoice",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-product": DialogProduct,
    "dialog-view-table": DialogViewTable,
    DialogReviewJournal,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      invoiceNumber: "",
      invoiceDate: moment().format("yyyy-MM-DD"),
      taxDate: moment().format("yyyy-MM-DD"),
      invoiceReceivedDate: moment().format("yyyy-MM-DD"),
      invoiceDueDate: moment().format("yyyy-MM-DD"),
      invoiceDuration: 0,
      vendorId: null,
      vendorName: "",
      type: "DO",
      deliveryOrders: [],
      purchaseOrders: [],
      roundType: null,
      roundAmount: 0,
      percentageDownPayment: 0,
      percentageInvoice: 100,
      percentagePaid: 0,
      percentagePpn: 11,
      currency: "IDR",
      fob: "",
      percentageUpTo: 0,
      billTo: "",
      address: "",
      taxInvoiceSerialNumber: "",
      remark: "",
      rate: 1,
      poDiscountType: 0,
      poDiscount: 0,
      totalAmount: 0,
      totalAmountIDR: 0,
      totalPoAmount: 0,
      totalPoAmountIDR: 0,
      totalPoAfterDiscountAmount: 0,
      totalPoAfterDiscountAmountIDR: 0,
      totalPoDiscountAmount: 0,
      totalPoDiscountAmountIDR: 0,
      totalDownPayment: 0,
      discountAmount: 0,
      discountAmountIDR: 0,
      taxAmount: 0,
      taxAmountIDR: 0,
      pphAmount: 0,
      pphAmountIDR: 0,
      totalAmountAfterDisc: 0,
      totalAmountAfterDiscIDR: 0,
      totalAmountAfterTax: 0,
      totalAmountAfterTaxIDR: 0,
      totalDpp: 0,
      chartOfAccountId: null,
      status: "Outstanding",
      downPaymentPercentageDtos: [],
      pphNumber: "",
      pphName: "",
      multiPo: true,
      percentages: [],
    },
    valid: true,
    menuInvoiceDate: false,
    menuInvoiceDueDate: false,
    menuInvoiceReceivedDate: false,
    menuTaxDate: false,
    form: {},
    items: [],
    vendor: null,
    deliveryOrder: null,
    purchaseOrders: [],
    deliveryOrders: [],
    accounts: [],
    address: {},
    pph: {},
    item: {},
    index: -1,
    dialog: false,
    dialogTableDo: false,
    dialogReview: false,
    totalDiscountPurchaseOrder: 0,
    totalQty: 0,
    listCalculation: [],
    journals: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Product Detail",
        value: "productDetail",
        sortable: false,
        divider: true,
      },
      {
        text: "Qty",
        value: "qty",
        sortable: false,
        divider: true,
      },
      {
        text: "U/M",
        value: "unitMeasure",
        sortable: false,
        divider: true,
      },
      {
        text: "U/Price",
        value: "unitPrice",
        sortable: false,
        divider: true,
      },
      {
        text: "Disc %",
        value: "discount",
        sortable: false,
        divider: true,
      },
      {
        text: `Amount`,
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "PO#",
        value: "poNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "DO#",
        value: "doNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Project",
        value: "project",
        sortable: false,
        divider: true,
      },
      {
        text: "Dept",
        value: "department",
        sortable: false,
        divider: true,
      },
      {
        text: "Reff",
        value: "reference",
        sortable: false,
        divider: true,
      },
      {
        text: "Account",
        value: "accountName",
        sortable: false,
        divider: true,
      },
    ],
    listRoundType: [{ text: "None", value: null }, "Income", "Expense"],
    listDp: [],
  }),

  computed: {
    totalAmount() {
      let subtotal = 0;
      this.items.map(key => {
        subtotal += key.qty * key.unitPrice;
      });
      return parseFloat(subtotal.toFixed(2));
    },

    ...mapState("purchaseInvoice", ["lastFormNumber", "lastPercentage"]),
    ...mapState("purchaseDownPayment", ["totalDownPayment"]),
    ...mapState("external", ["vendors"]),
    percentageInvoiceRules() {
      let total = 0;
      this.totalDownPayment.forEach(x => {
        total += x.percentage;
      });
      return [
        v => !!v || "Percentage invoice is required",
        v =>
          (v <= 100 - total && v >= 1) ||
          `Percentage invoice must be more than 1 and less than ${100 - total}`,
      ];
    },
    totalPercentage() {
      let total = 0;
      this.totalDownPayment.forEach(x => {
        total += x.percentage;
      });
      return total;
    },
  },

  watch: {
    totalAmount(val) {
      if (this.form.type === "DO") {
        if (this.form.currency != "IDR") {
          this.form.totalAmount = parseFloat(val.toFixed(2));
          if (this.form.poDiscountType === 1) {
            this.form.discountAmount = parseFloat(
              (parseFloat(val.toFixed(2)) * (this.purchaseOrder.disc_value / 100)).toFixed(2)
            );
          } else if (this.form.poDiscountType === 2) {
            this.form.discountAmount = this.purchaseOrder.disc_value;
          }
          this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
          this.form.taxAmount = parseFloat(
            (this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100)).toFixed(2)
          );
        } else {
          this.form.totalAmount = parseFloat(val.toFixed(0));
          if (this.form.poDiscountType === 1) {
            this.form.discountAmount = parseFloat(
              (parseFloat(val.toFixed(0)) * (this.purchaseOrder.disc_value / 100)).toFixed(0)
            );
          } else if (this.form.poDiscountType === 2) {
            this.form.discountAmount = this.purchaseOrder.disc_value;
          }
          this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
          this.form.taxAmount = parseFloat(
            (this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100)).toFixed(0)
          );
        }

        this.form.totalAmountIDR = this.form.totalAmount * this.form.rate;
        this.form.totalAmountAfterDiscIDR = this.form.totalAmountAfterDisc * this.form.rate;

        this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
        this.form.discountIDR = this.form.discountAmount * this.form.rate;
        this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
        this.form.totalAmountAfterTaxIDR = this.form.totalAmountAfterTax * this.form.rate;
      } else {
        if (this.form.poDiscountType === 1) {
          this.form.totalPoDiscountAmount =
            parseFloat(val.toFixed(2)) * (this.purchaseOrder.disc_value / 100);
          this.form.discountAmount = parseFloat(
            (parseFloat(val.toFixed(2)) * (this.purchaseOrder.disc_value / 100)).toFixed(2)
          );
        } else if (this.form.poDiscountType === 2) {
          this.form.totalPoDiscountAmount = this.purchaseOrder.disc_value;
          this.form.discountAmount = this.purchaseOrder.disc_value;
        }
        this.form.totalPoAfterDiscountAmount =
          parseFloat(val.toFixed(2)) - this.form.totalPoDiscountAmount;
        this.form.totalPoAmount = parseFloat(val.toFixed(2));
      }
      this.form.totalPoDiscountAmountIDR = this.form.totalPoDiscountAmount * this.form.rate;
      this.form.totalPoAfterDiscountAmountIDR =
        this.form.totalPoAfterDiscountAmount * this.form.rate;
      this.form.totalPoAmountIDR = this.form.totalPoAmount * this.form.rate;
    },
    form(val) {
      this.calculatePercentagePaid(val.totalPoAfterDiscountAmount);
    },
  },

  methods: {
    checkJournal() {
      this.form.totalDownPayment = (this.totalPercentage / 100) * this.form.totalPoAmount;
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.journals = [
          {
            accountNumber: this.getAccountNumberDebt(this.form.currency),
            accountName: `HUTANG LAIN-LAIN ${this.form.currency}`,
            debit: this.form.totalAmountAfterDisc,
            credit: 0,
            debitIDR: this.form.totalAmountAfterDiscIDR,
            creditIDR: 0,
          },
          {
            accountNumber: this.accountNumber,
            accountName: `${this.form.vendorName} ${this.form.currency}`,
            debit: 0,
            credit: this.form.totalAmountAfterTax,
            debitIDR: 0,
            creditIDR: this.form.totalAmountAfterTaxIDR,
          },
        ];
        this.form.downPaymentPercentageDtos.forEach(x => {
          this.journals.push({
            accountNumber: this.getAccountNumber(this.form.currency),
            accountName: `UANG MUKA PEMBELIAN ${this.form.currency}`,
            debit: 0,
            credit: x.totalAmount,
            debitIDR: 0,
            creditIDR: x.totalAmount * this.form.rate,
          });
        });
        if (this.form.taxAmount > 0) {
          this.journals.unshift({
            accountNumber: "180.00001",
            accountName: `PAJAK MASUKAN`,
            debit: this.form.taxAmount,
            credit: 0,
            debitIDR: this.form.taxAmountIDR,
            creditIDR: 0,
          });
        }
        if (this.form.roundType === "Income") {
          this.journals.unshift({
            accountNumber: "691.00000",
            accountName: "SELISIH KURS",
            debit: this.form.roundAmount,
            credit: 0,
            debitIDR: this.form.roundAmount * this.form.rate,
            creditIDR: 0,
          });
        } else if (this.form.roundType === "Expense") {
          this.journals.push({
            accountNumber: "691.00000",
            accountName: "SELISIH KURS",
            debit: 0,
            credit: this.form.roundAmount,
            debitIDR: 0,
            creditIDR: this.form.roundAmount * this.form.rate,
          });
        }
        if (this.form.pphAmount > 0) {
          this.journals.push({
            accountNumber: this.pph.accountNumber,
            accountName: this.pph.accountName,
            debit: 0,
            credit: this.form.pphAmount,
            debitIDR: 0,
            creditIDR: this.form.pphAmount,
          });
        }
        this.journals.forEach(x => {
          this.$store
            .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
            .then(response => (x.pointers = response.data));
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    async submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.percentageDownPayment = this.totalPercentage;
        this.form.totalDownPayment = (this.totalPercentage / 100) * this.form.totalPoAmount;
        this.form.products = this.items;
        this.form.downPaymentPercentageDtos = this.totalDownPayment;

        let totalDebit =
          this.form.totalAmountAfterDisc -
          this.form.pphAmount +
          this.form.taxAmount +
          this.form.totalDownPayment;
        let totalCredit = this.form.totalDownPayment + this.form.totalAmountAfterTax;

        if (totalDebit.toFixed(2) - totalCredit.toFixed(2) === 0) {
          this.$store.commit("SET_LOADER", true);
          const response = await this.$store.dispatch("purchaseInvoice/create", this.form);
          if (response.status === 200) {
            this.$store.commit("SET_LOADER", false);
            this.clear();
          }
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Credit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    async getAP(id) {
      await this.$store
        .dispatch("chartOfAccount/getAP", { id: id, code: this.form.currency })
        .then(response => (this.accountNumber = response.data));
    },
    getAccountNumber() {
      if (this.form.currency === "IDR") {
        return "150.00001";
      } else if (this.form.currency === "SGD") {
        return "150.00002";
      } else if (this.form.currency === "USD") {
        return "150.00003";
      } else if (this.form.currency === "AUD") {
        return "150.00006";
      } else if (this.form.currency === "YEN") {
        return "150.00005";
      } else {
        return "150.00004";
      }
    },
    getAccountNumberDebt() {
      if (this.form.currency === "IDR") {
        return "391.00000";
      } else if (this.form.currency === "SGD") {
        return "392.00000";
      } else if (this.form.currency === "USD") {
        return "393.00000";
      } else if (this.form.currency === "AUD") {
        return "150.00006";
      } else if (this.form.currency === "YEN") {
        return "150.00005";
      } else {
        return "395.00000";
      }
    },
    async getPurchaseOrders(id) {
      const response = await this.$store.dispatch("external/getPurchaseOrders", id);
      if (response.status === 200) {
        this.purchaseOrders = response.data;
      }
    },
    async calculateTotalPO(id, items) {
      this.$store.commit("SET_LOADER", true);
      this.totalQty = 0;
      const response = await this.$store.dispatch("external/getPurchaseOrderProducts", id);
      if (response.status === 200) {
        let totalPo = 0;
        response.data.forEach(x => {
          totalPo += x.qty_pos * x.unit_price;
          this.form.totalPoAmount += parseFloat((x.qty_pos * x.unit_price).toFixed(2));
          this.totalQty += x.qty_pos;
        });
        let arraysUnique = items.filter(x => x.po_detail.pos_id === id);
        let total = 0;
        arraysUnique.forEach(x => {
          total += x.qty_receive * x.po_detail.unit_price;
        });
        this.form.percentages.push({
          percentage: (parseFloat(total.toFixed(2)) / parseFloat(totalPo.toFixed(2))) * 100,
          poId: id,
          total: parseFloat(totalPo.toFixed(2)),
          totalRr: parseFloat(total.toFixed(2)),
        });
        this.form.totalPoAfterDiscountAmount = parseFloat(
          (this.form.totalPoAmount - this.form.totalPoDiscountAmount).toFixed(2)
        );
        this.$store.commit("SET_LOADER", false);
      }
    },
    async setProductDO(id) {
      this.$store.commit("SET_LOADER", true);
      const response = await this.$store.dispatch("external/getDeliveryOrderDetail", id);
      if (response.status === 200) {
        if (response.data.reportdetail[0].po_detail !== null) {
          this.form.currency = response.data.reportdetail[0].po_detail.curr;
          if (response.data.reportdetail[0].po_detail.curr === "RP") {
            this.form.currency = "IDR";
          }
          if (response.data.reportdetail[0].po_detail.curr === "S$") {
            this.form.currency = "SGD";
          }
          if (response.data.reportdetail[0].po_detail.curr === "US$") {
            this.form.currency = "USD";
          }
          this.vendor = this.vendors.filter(x => x.id === response.data.source_id)[0];
          this.form.vendorId = response.data.source_id;
          this.form.vendorName = this.vendor.supplier_name;
          this.form.billTo = this.vendor.supplier_name;
          this.getAP(response.data.source_id);
          this.getVendorAddress(response.data.source_id);
          this.getRate({
            code: response.data.reportdetail[0].po_detail.curr,
            date: this.form.invoiceDate,
          });
          this.changeHeader();
          await this.getPurchaseOrders(response.data.source_id).then(() => {
            this.getListPoId(response.data.reportdetail);
            let total = 0;
            response.data.reportdetail.forEach(x => {
              let po = this.purchaseOrders.filter(y => y.id === x.po_detail.pos_id)[0];
              total += x.qty_receive * x.po_detail.unit_price;
              this.items.push({
                productId: x.product_id,
                productDetail: x.products.part_desc,
                partNumber: x.products.part_num,
                partName: x.products.part_name,
                mfr: x.products.mfr,
                partDesc: x.products.part_desc,
                qty: x.qty_receive,
                unitPrice: x.po_detail.unit_price,
                unitMeasure: x.products.default_um,
                discount: x.discount,
                total: x.qty_receive * x.po_detail.unit_price,
                reference: x.reference,
                poNumber: po.name,
                // poNumber: x.po_detail.pos_id,
                doNumber: response.data.document.reference_rr,
                department: x.department,
                accountNumber: x.accountNumber,
                accountName: x.accountName,
                rrId: response.data.id,
              });
            });
            this.listCalculation.push(((total / this.form.totalPoAmount) * 100).toFixed(2));
            this.form.deliveryOrders.push(this.deliveryOrder);
            if (this.form.currency != "IDR") {
              this.form.totalAmount = this.totalAmount * (this.form.percentageInvoice / 100);
              if (this.form.poDiscountType === 1) {
                this.form.discountAmount = parseFloat(
                  (this.totalAmount * (this.purchaseOrder.disc_value / 100)).toFixed(2)
                );
              } else if (this.form.poDiscountType === 2) {
                this.form.discountAmount = this.purchaseOrder.disc_value;
              }
              this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
              this.form.taxAmount = parseFloat(
                (this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100)).toFixed(2)
              );
            } else {
              this.form.totalAmount = parseFloat(
                (this.totalAmount * (this.form.percentageInvoice / 100)).toFixed(0)
              );
              if (this.form.poDiscountType === 1) {
                this.form.discountAmount = parseFloat(
                  (this.totalAmount * (this.purchaseOrder.disc_value / 100)).toFixed(0)
                );
              } else if (this.form.poDiscountType === 2) {
                this.form.discountAmount = this.purchaseOrder.disc_value;
              }
              this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
              this.form.taxAmount = parseFloat(
                (this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100)).toFixed(0)
              );
            }

            this.form.totalAmountIDR = this.form.totalAmount * this.form.rate;

            this.form.totalAmountAfterDiscIDR = this.form.totalAmountAfterDisc * this.form.rate;

            this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
            this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
            this.form.totalAmountAfterTaxIDR = this.form.totalAmountAfterTax * this.form.rate;
          });
        } else {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Data PO Vendor Belum link",
          });
          this.$store.commit("SET_LOADER", false);
        }
      }
    },
    async getRate(params) {
      this.$store
        .dispatch("currency/getRate", params)
        .then(response => (this.form.rate = response.data));
    },
    getListPoId(items) {
      let arrays = [];
      items.forEach(x => {
        arrays.push(x.po_detail.pos_id);
      });
      let arraysUnique = arrays.filter((v, i, a) => a.indexOf(v) === i);

      arraysUnique.forEach(x => {
        this.form.purchaseOrders.push(this.purchaseOrders.filter(y => y.id === x)[0]);
        this.calculateTotalPO(x, items);
      });
      arraysUnique.forEach(async x => {
        this.$store.dispatch("purchaseDownPayment/getTotalDownPayment", x).then(() => {
          this.totalDownPayment.forEach(x => {
            this.form.downPaymentPercentageDtos.push(x);
          });
        });
      });
    },
    async getVendorAddress(id) {
      const response = await this.$store.dispatch("external/getVendorAddress", id);
      if (response.status === 200) {
        this.address = response.data;
        this.form.address =
          response.data.address_line_1 +
          ", " +
          response.data.post_code +
          ", " +
          response.data.city +
          ", " +
          response.data.country_id;
      }
    },
    calculatePercentagePaid(val) {
      this.form.percentagePaid = (this.form.totalAmount / val) * 100;
    },
    async addDeliveryOrder() {
      this.form.percentagePaid = 0;
      if (this.form.deliveryOrders.indexOf(this.deliveryOrder) !== -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "DO sudah dipakai pada form",
        });
      } else {
        if (this.deliveryOrder === null) {
          this.$store.dispatch("toast", {
            type: "error",
            message: "DO harus ditambahkan",
          });
        } else {
          await this.setProductDO(this.deliveryOrder.id)
            .then(() => {
              this.form.percentageUpTo =
                parseFloat(this.lastPercentage) +
                parseFloat(this.totalPercentage) +
                this.form.percentagePaid;
              this.$store.commit("SET_LOADER", false);
            })
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        }
      }
    },
    viewTableDo() {
      this.dialogTableDo = true;
    },
    deleteItemTableDo(item) {
      const index = this.form.deliveryOrders.map(x => x).indexOf(item);
      if (index > -1) {
        this.form.deliveryOrders.splice(index, 1);
        this.form.percentageUpTo -= this.listCalculation[index];
        this.form.percentagePaid -= this.listCalculation[index];
        this.items
          .filter(x => x.rrId === item.id)
          .forEach(x => {
            const index = this.items.indexOf(x);
            if (index > -1) this.items.splice(index, 1);
          });
        this.listCalculation.splice(index, 1);
      }
    },
    percentageUpTo() {
      this.form.percentagePaid = (this.form.totalAmount / this.form.totalPoAmount) * 100;
      this.form.percentageUpTo =
        this.form.percentagePaid + this.lastPercentage + this.totalPercentage;
    },
    onInputInvoiceDuration(val) {
      if (this.form.currency !== "IDR") {
        this.getRate({ code: this.form.currency, date: val });
      } else {
        this.form.rate = 1;
      }
      this.form.invoiceDueDate = moment(this.form.invoiceReceivedDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
    },
    onInputInvoice() {
      if (this.form.currency != "IDR") {
        this.form.totalAmount = this.totalAmount * (this.form.percentageInvoice / 100);
        this.form.discountAmount = this.form.discountAmount = parseFloat(
          (this.totalAmount * (this.purchaseOrder.disc_value / 100)).toFixed(2)
        );
      } else {
        this.form.totalAmount = parseFloat(
          (this.totalAmount * (this.form.percentageInvoice / 100)).toFixed(0)
        );
        this.form.discountAmount = this.form.discountAmount = parseFloat(
          (this.totalAmount * (this.purchaseOrder.disc_value / 100)).toFixed(0)
        );
      }

      this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
      this.form.totalAmountAfterTax =
        this.form.totalAmountAfterDisc - this.form.pphAmount + this.form.taxAmount;
      this.form.totalAmountIDR = this.form.totalAmount * this.form.rate;
      this.form.discountAmountIDR = this.form.discountAmount * this.form.rate;
      this.form.totalAmountAfterDiscIDR = this.form.totalAmountAfterDisc * this.form.rate;
      this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
      this.form.totalAmountAfterTaxIDR = this.form.totalAmountAfterTax * this.form.rate;
      this.form.pphAmountIDR = this.form.pphAmount * this.form.rate;
      this.percentageUpTo();
    },
    onChangePph(val) {
      this.form.pphNumber = val.accountNumber;
      this.form.pphName = val.accountName;
    },
    onInputPpn() {
      if (this.form.currency != "IDR") {
        this.form.taxAmount = this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100);
      } else {
        this.form.taxAmount = parseFloat(
          (this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100)).toFixed(0)
        );
      }

      this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
      this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
      this.form.totalAmountAfterTaxIDR = this.form.totalAmountAfterTax * this.form.rate;
    },
    onInputTax() {
      this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
      this.form.pphAmountIDR = this.form.pphAmount * this.form.rate;
      this.form.discountAmountIDR = this.form.discountAmount * this.form.rate;
      this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
      this.form.totalAmountAfterDiscIDR = this.form.totalAmountAfterDisc * this.form.rate;
      this.form.totalAmountAfterTax =
        this.form.totalAmountAfterDisc - this.form.pphAmount + this.form.taxAmount;
      this.form.totalAmountAfterTaxIDR =
        this.form.totalAmountAfterDiscIDR - this.form.pphAmountIDR + this.form.taxAmountIDR;
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    save(item) {
      this.items[this.index] = item;
    },
    close() {
      this.dialog = this.dialogTableDo = this.dialogReview = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    clear() {
      this.vendor = null;
      this.purchaseOrder = null;
      this.deliverOrder = null;
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    onClickProduct(item) {
      this.item = item;
      this.index = this.items.findIndex(x => x === item);
      this.dialog = true;
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getListAccountGL");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    async getDeliveryOrders() {
      const response = await this.$store.dispatch("external/getDeliveryOrderAll");
      if (response.status === 200) {
        this.deliveryOrders = response.data;
      }
    },
    getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getAccounts(),
        this.$store.dispatch("purchaseInvoice/getLastFormNumber"),
        this.$store.dispatch("external/getVendors"),
        this.getDeliveryOrders(),
      ])
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    changeHeader() {
      this.headers = [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "Product Detail",
          value: "productDetail",
          sortable: false,
          divider: true,
        },
        {
          text: "Qty",
          value: "qty",
          sortable: false,
          divider: true,
        },
        {
          text: "U/M",
          value: "unitMeasure",
          sortable: false,
          divider: true,
        },
        {
          text: "U/Price",
          value: "unitPrice",
          sortable: false,
          divider: true,
        },
        {
          text: "Disc %",
          value: "discount",
          sortable: false,
          divider: true,
        },
        {
          text: `Amount (${this.form.currency})`,
          value: "total",
          sortable: false,
          divider: true,
        },
        {
          text: "PO#",
          value: "poNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "DO#",
          value: "doNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Project",
          value: "project",
          sortable: false,
          divider: true,
        },
        {
          text: "Dept",
          value: "department",
          sortable: false,
          divider: true,
        },
        {
          text: "Reff",
          value: "reference",
          sortable: false,
          divider: true,
        },
        {
          text: "Account",
          value: "accountName",
          sortable: false,
          divider: true,
        },
      ];
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.remark.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    // eslint-disable-next-line no-unused-vars
    filterObjectDo(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.do_num.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    onChangeTotalInvoice(val) {
      this.form.totalAmountAfterDisc = val - this.form.discountAmount;
      this.form.totalAmountAfterTax =
        this.form.totalAmountAfterDisc - this.form.pphAmount + this.form.taxAmount;
      this.form.totalAmountIDR = this.form.totalAmount * this.form.rate;
      this.form.totalAmountAfterDiscIDR = this.form.totalAmountAfterDisc * this.form.rate;
      this.form.totalAmountAfterTaxIDR = this.form.totalAmountAfterTax * this.form.rate;
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
